<template>
  <div class="doing__container">
    <div class="doing__container__one">

      <div class="doing__title">

          <h2 class="doing__title__text doing__title__text__position">
            WHAT ARE<br><!--<span class="doing__title__text__another__color">-->WE DOING?<!--</span>-->
          </h2>
        <div class="doing__span__container">
          <span class="doing__span__one"></span>
          <span class="doing__span__two"></span>
        </div>
        <p class="doing__title__text__small__text">
          We are making imperfections perfect
<!--
          <br>
          <br>
          We look for problem areas in every piece of the frame.
          <br>
          <br>
          Every frame is polished until it’s perfect
-->

        </p>
      </div>
      <div class="mobile__this__block__revers">
      <div class="doing__additional__text">
        <span class="container__additional__text__one additional__margin">
          <p class="additional__text__one">Skin retouching</p><p class="additional__text__two">Eyes / Teeth Whitening</p>
        </span>
        <span class="additional__text__line "></span>
        <span class="container__additional__text__one">
        <p class="additional__text__tree">Compositing</p><p class="additional__text__four">Body sculpting / reshaping</p>
        </span>
      </div>
      <router-link :to="{name: 'portfolio'}">
      <div class="doing__portfolio__button">
        <ButtonsRouter
        :text="'Show services'"
        />
<!--        <img src="@/assets/img/portfolio__button.svg" alt="" class="portfolio__button">
        <img src="@/assets/img/portfolio__arrow__button.svg" alt="" class="portfolio__button__arrow">-->
      </div>
        <img src="@/assets/img/button__portfolio__mobile.svg" alt="" class="portfolio__button__mobile">

      </router-link>
      </div>
    </div><!--headphones__mobile-->
    <div class="doing__container__two">
<!--
      <img src="@/assets/img/product__headphones.png" alt="" class="headphones__product">
-->
      <img src="https://dbwImages.b-cdn.net/product__headphones.png" alt="" class="headphones__product">


<!--
      <img src="@/assets/img/headphones__mobile.svg" alt="" class="headphones__product__mobile">
-->

      <img src="https://dbwImages.b-cdn.net/headphones__mobile.svg" alt="" class="headphones__product__mobile">


      <img src="@/assets/img/ellipse__one.png" alt="" class="ellipse__one">
      <img src="@/assets/img/ellipse__two.png" alt="" class="ellipse__two">

<!--
      <img src="@/assets/img/ellipse__mobile__one.svg" alt="" class="ellipse__mobile__one">
-->
      <img src="https://dbwImages.b-cdn.net/ellipse__mobile__one.svg" alt="" class="ellipse__mobile__one">


<!--
      <img src="@/assets/img/ellipse__mobile__two.svg" alt="" class="ellipse__mobile__two">
-->
      <img src="https://dbwImages.b-cdn.net/ellipse__mobile__two.svg" alt="" class="ellipse__mobile__two">


      <img src="@/assets/img/plus__one.png" alt="" class="plus__one">
      <img src="@/assets/img/plus__one.png" alt="" class="plus__two">
      <img src="@/assets/img/plus__mobile.svg" alt="" class="plus__mobile__one">
      <img src="@/assets/img/plus__mobile.svg" alt="" class="plus__mobile__two">
      <img src="@/assets/img/vector__one.svg" alt="" class="vector__one">
      <img src="@/assets/img/vector__two.svg" alt="" class="vector__two">
      <img src="@/assets/img/vector__mobile.svg" alt="" class="vector__mobile">
      <img src="@/assets/img/vector__mobile.svg" alt="" class="vector__mobile__two">
      <div class="headphones__text__one ">Retouch surface textures</div>
      <div class="headphones__text__two">improve the texture of any surface</div>
      <div class="headphones__text__one__mobile">Retouch surface textures</div>
      <div class="headphones__text__two__mobile">improve the texture of any surface</div>

      <div class="doing__title product__title">

        <h2 class="doing__title__text product__title__text__position">
          PRODUCT<br>
          <span class="product__title__text__another__color">RETOUCHING</span>
        </h2>

        <div class="doing__span__container">
          <span class="doing__span__one product__span__one"></span>
          <span class="doing__span__two product__span__two"></span>
        </div>
        <p class="doing__title__text__small__text product__small__text">
          Sharpening details, smoothing
          textures for a pristine finish.
        </p>
        <p class="doing__title__text__small__text product__small__text">
          We meld accuracy with artistic
          flair to elevate product retouching.

        </p>
        <p class="doing__title__text__small__text product__small__text">
          From our origins, we've been mastering
          the art of perfecting product imagery,
          setting the stage for unparalleled displays

          <!--          <br>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          <br>-->
<!--          <br><span class="product__small__text__mobile__none">
          We specialise in removing imperfections, adjusting colors and lighting, and enhancing textures to bring out the beauty and quality of your products. Additionally, we offer background removal and replacement services, adding a professional touch that sets your visuals apart from the competition.
          </span>-->
        </p>
      </div>

    </div>
  </div>
</template>

<script>
/*
import ButtonsRouter from "./Buttons-Router";
*/
const ButtonsRouter = () => import('./Buttons-Router');

export default {
  name: "WhatAreWeDoing",
  components: {ButtonsRouter}

}
</script>

<style scoped lang="scss">
.doing__container {
  width: 100%;
  height: 111.927vw;
  /*
  background-image: url("~@/assets/img/we__do__background__desck.png");
  */
  background-image: url("https://dbwImages.b-cdn.net/we__do__background__desck.png");
  background-size: cover;



}
.margin_retuching_text{
  margin-top: 1.09vw;
}

.doing__container__one {
  width: 100%;
  height: 66.510vw;
  display: flex;
  flex-direction: column;
  align-items: end;

overflow: hidden;

  -webkit-box-shadow: inset 0px 200px 55px -182px rgba(0,0,0,1);
  -moz-box-shadow: inset 0px 200px 55px -182px rgba(0,0,0,1);
  box-shadow: inset 0px 200px 55px -182px rgba(0,0,0,1);
}

.doing__container__two {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 45.417vw;
}

.doing__title{
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 24.531vw;
  height: 13.469vw;
 /* margin-bottom: 3.177vw;*/
  margin-top: 14.948vw;
  margin-right: 8.385vw;
}
.doing__title__text{
  width: 17.240vw;
  height: 5.104vw;
  text-align: right;
  font-size: 2.083vw;
  font-family: 'Benzin-ExtraBold';
  line-height: 2.448vw;
  color: #FFFFFF;
  margin-bottom: 1.927vw;
}
.doing__title__text__another__color{
  color: rgba(0, 139, 72, 1);
}
.doing__title__text__small__text{
  font-size: 0.938vw;
  font-family: 'Benzin-Regular';
  line-height: 100%;
  text-align: right;
  color: #FFFFFF;
}
.doing__span__container{
  display: flex;
  width: 20.885vw;
  height: 0.156vw;
  margin-bottom: 2.604vw;
}
.doing__span__one{
  height: 0.156vw;
  width: 10.313vw;
  background: rgba(255, 255, 255, 1);
}
.doing__span__two{
  height: 0.156vw;
  width: 10.313vw;
  background: rgba(219, 16, 35, 1);
}

.product__title{
  margin-top: 10.302vw;
  margin-left: 8.333vw;
  width: 25.677vw;
  height: 21.510vw;
  align-items: flex-start;
}
.product__span__one{
  background: rgba(219, 16, 35, 1);
}
.product__span__two{
  background: rgba(255, 255, 255, 1);
}
.product__title__text__position{
  text-align: left;
}
.product__title__text__another__color{
  color: rgba(219, 16, 35, 1);
}
.product__small__text{
  text-align: left;
  line-height: 140%;
  margin-bottom: 1vw;
}
.doing__additional__text{
  /*
  margin-right: 8.385vw;
  */
  margin-bottom: 3.385vw;
  width: 30.625vw;
  height: 4.375vw;
  position: relative;
  font-family: 'Benzin-Regular';
  font-size: 0.938vw;
  line-height: 143.9%;
  color: #FFFFFF;
}
.additional__text__line{
  rotate: 305deg;
  left: 12.5vw;
  top: 2vw;
  position: absolute;
  width: 4.792vw;
  height: 0.156vw;
  background: rgba(219, 16, 35, 1);
}
.container__additional__text__one{
  display: flex;
  justify-content: flex-end;
}
.additional__margin{
  margin-bottom: 1.042vw;
}
.additional__text__one{
  margin-right: 4.133vw;
}
.additional__text__tree{
  margin-right: 3.661vw;
}
.doing__portfolio__button{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.542vw;
  width: 24.063vw;
}
.portfolio__button{
  width: 18.333vw;
  height: 3.542vw;
}
.portfolio__button__arrow{
  width: 4.635vw;
  height: 1.104vw;
}
.portfolio__button__mobile{
  display: none;
}
.mobile__this__block__revers{
  margin-right: 8.385vw;
  display: flex;
  align-items: end;
  flex-direction: column;
}
.headphones__product{
  width: 56.04vw;
  height: 56.04vw;
  position: absolute;
  top: -4vw;
  right: 2vw;
}
.headphones__product__mobile{
  display: none;
}
.ellipse__one{
  position: absolute;
  z-index: 5;
  width: 2.682vw;
  height: 2.682vw;
  top: 9.6vw;
  right: 30vw;
}
.ellipse__two{
  position: absolute;
  z-index: 5;
  width: 2.682vw;
  height: 2.682vw;
  bottom: 9.5vw;
  right: 35vw;

}
.ellipse__mobile__one{
  display: none;
}
.ellipse__mobile__two{
  display: none;
}
.plus__one{
  width: 4.688vw;
  height: 4.688vw;
  display: block;
  position: absolute;
  z-index: 5;
  right: 25.5vw;
  top: 8.6vw;
}
.plus__two{
  width: 4.688vw;
  height: 4.688vw;
  display: block;
  position: absolute;
  z-index: 6;
  right: 31.5vw;
  top: 32.9vw;
  rotate: 95deg;
}
.plus__mobile__one{
  display: none;
}
.plus__mobile__two{
  display: none;
}
.vector__one{
  position: absolute;
  top: 4.3vw;
  right: 27.9vw;
  width: 12.396vw;
  height: 5.078vw;
}
.vector__two{
  display: block;
  position: absolute;
  bottom: 3.4vw;
  right: 23.3vw;
  width: 10.313vw;
  height: 6.823vw;

}
.vector__mobile{
  display: none;
}
.vector__mobile__two{
  display: none;
}
.headphones__text__one{
  display: block;
  position: absolute;
  top: 3vw;
  right: 40vw;
  width: 14.271vw;
  height: 3.333vw;
  font-size: 1.354vw;
  font-family: 'Benzin-Regular';
  line-height: 143.9%;
  color: #F2F2F2;
}
.headphones__text__two {
  display: block;
  position: absolute;
  bottom: -0.1vw;
  right: 14.8vw;
  width: 15.677vw;
  height: 3.333vw;
  font-size: 1.354vw;
  font-family: 'Benzin-Regular';
  line-height: 143.9%;
  color: #F2F2F2;
}
.headphones__text__one__mobile{
  display: none;
}
.headphones__text__two__mobile{
  display: none;
}


@media screen and(max-width: 991px) {
  .doing__container {
   /* display: flex;
    flex-direction: column;
*/
    overflow: hidden;
    width: 100%;
    height: 320.000vw;
    background-image: url("~@/assets/img/we__do__background__mobile.png");
    background-size: cover;
  }
  .doing__container__one {
    width: 100%;
    height: 162.133vw;

    position: relative;

    /*display: flex;
    flex-direction: column;
    align-items: end;*/

  }
  .doing__container__two {
    position: relative;
    width: 100%;
    height: 158vw;
  }
  .doing__title{
    position: absolute;
    top: 18.667vw;
    right:  6.400vw;
    margin: 0;
    /*margin-top: 18.667vw;
    margin-right: 6.400vw;
    margin-bottom: 16.533vw;*/
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 57.600vw;
    height: 69.600vw;


  }
  .doing__title__text{
    width: 42.667vw;
    height: 13.600vw;
    text-align: right;
    font-size: 5.333vw;
    font-family: 'Benzin-ExtraBold';
    line-height: 6.133vw;
    color: #FFFFFF;
    margin-bottom: 0;
  }
  .doing__title__text__small__text{
    font-size: 3.733vw;
    line-height: 130%;
    text-align: right;
  }
  .doing__span__container{
    justify-content: right;
    width: 50.667vw;
    height: 0.267vw;
    margin-bottom: 6.533vw;
    margin-top: 6.800vw;

  }
  .doing__span__one{
    height: 0.267vw;
    width: 25.019vw;
  }
  .doing__span__two{
    height: 0.267vw;
    width: 25.019vw;
  }
  .product__title{
    /*margin-top: 18.933vw;
    margin-left: 6.133vw;*/
    left: 6vw;
    width: 83.667vw;
    height: 56.400vw;
    align-items: flex-start;
  }
  .product__title__text__position{
    text-align: left;
  }
  .product__span__one{
    background: rgba(219, 16, 35, 1);
  }
  .product__span__two{
    background: rgba(255, 255, 255, 1);
  }
  .product__small__text{
    text-align: left;
    line-height: 140%;
  }
  .product__small__text__mobile__none{
    display: none;
  }
  .doing__additional__text{
    margin-bottom: 0;
    /*
    margin-right: 6.400vw;
    */
    position: relative;

    top: 126vw;
    width: 90.933vw;
    height: 18.933vw;
    font-size: 3.733vw;
  }
  .additional__text__line{
    top: 10vw;
    left: 24vw;
    width: 23.067vw;
    height: 0.267vw;
    rotate: 282deg;
  }
  .additional__margin{
    margin-bottom: 4.267vw;
  }
  /*.additional__text__one{
    margin-right: 6.133vw;
  }*/
  .additional__text__one{
    margin-right: 6.133vw;
  }
  .additional__text__tree{
    margin-right: 12.800vw;
  }
  .doing__portfolio__button{
    height: 9.333vw;
    width: 62.667vw;
    display: none;
  }
  .portfolio__button__mobile{
    position: absolute;
    top: 102.2vw;
    left: 50%;
    transform: translate(-50%);
    height: 9.333vw;
    width: 62.667vw;
    display: block;
    /*
    margin-bottom: 11.467vw;
    */
  }
  .portfolio__button{
   display: none;
  }
  .portfolio__button__arrow{
    display: none;
  }
  .mobile__this__block__revers{
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .headphones__product{
    display: none;
  }

  .headphones__product__mobile{
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 70.667vw;
    width: 70.667vw;
    rotate: -13deg;
  }
  .ellipse__one{
   display: none;
  }
  .ellipse__two{
    display: none;

  }
  .ellipse__mobile__one{
    width: 4.000vw;
    height: 4.000vw;
    display: block;
    position: absolute;
    bottom: 46vw;
    right: 34.7vw;
  }
  .ellipse__mobile__two{
    width: 4.000vw;
    height: 4.000vw;
    display: block;
    position: absolute;
    bottom: 30vw;
    right: 43.7vw;
    rotate: 149deg;
  }
  .plus__one{
    display: none;
  }
  .plus__two{
    display: none;
  }
  .plus__mobile__one{
    display: block;
    width: 7.157vw;
    height: 7.157vw;
    position: absolute;
    bottom: 44.5vw;
    right: 26vw;
  }
  .plus__mobile__two{
    display: block;
    width: 7.157vw;
    height: 7.157vw;
    position: absolute;
    bottom: 28.5vw;
    right: 36vw;
  }
  .vector__one{
    display: none;
  }
  .vector__two{
    display: none;
  }
  .vector__mobile{
    width: 18.027vw;
    height: 4.440vw;
    display: block;
    position: absolute;
    bottom: 35vw;
    right: 39vw;
  }
  .vector__mobile__two{
    width: 18.027vw;
    height: 4.440vw;
    position: absolute;
    display: block;
    bottom: 49vw;
    right: 29vw;
  }
  .headphones__text__one__mobile{
    display: block;
    position: absolute;
    top: 102vw;
    left: 14vw;
    width: 38.933vw;
    height: 9.067vw;
    font-family: 'Benzin-Regular';
    font-size: 3.733vw;
    line-height: 122.9%;
    color: #F2F2F2;

  }
  .headphones__text__two__mobile{
    display: block;
    position: absolute;
    bottom: 34vw;
    left: 10vw;
    width: 42.667vw;
    height: 10.667vw;
    font-family: 'Benzin-Regular';
    font-size: 3.733vw;
    line-height: 122.9%;
    color: #F2F2F2;
  }
  .headphones__text__one{
    display: none;
  }
  .headphones__text__two{
    display: none;
  }
}

</style>